@mixin vertical-align {
  @include transform(translateY(-50%));
  position: relative;
  top: 50%;
}

@mixin clear-vertical-align {
  @include transform(none);
  top: auto;
}

@mixin nowrap {
  white-space: nowrap;
}


// Media queries

@mixin media($media) {

  @if $media == tablet {
    @media only screen and (min-width: 768px) { @content; }
  }

  @if $media == desktop {
    @media only screen and (min-width: 1180px) { @content; }
  }
}