@import 'https://fonts.googleapis.com/css?family=Sanchez';

@font-face {
    font-family: 'GothamBook';
    src: url('../fonts/Gotham/Gotham-Book.eot');
    src: url('../fonts/Gotham/Gotham-Book.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Gotham/Gotham-Book.ttf') format('truetype'),
         url('../fonts/Gotham/Gotham-Book.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GothamThin';
    src: url('../fonts/Gotham/Gotham-Thin.eot');
    src: url('../fonts/Gotham/Gotham-Thin.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Gotham/Gotham-Thin.ttf') format('truetype'),
         url('../fonts/Gotham/Gotham-Thin.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GothamLight';
    src: url('../fonts/Gotham/Gotham-Light.eot');
    src: url('../fonts/Gotham/Gotham-Light.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Gotham/Gotham-Light.ttf') format('truetype'),
         url('../fonts/Gotham/Gotham-Light.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'Circular-Air-Bold';
  src: url('../fonts/Circular/Circular Air-Bold.eot');
  src: url('../fonts/Circular/Circular Air-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Circular/Circular Air-Bold.woff') format('woff'),
    url('../fonts/Circular/Circular Air-Bold.ttf')  format('truetype'),
    url('../fonts/Circular/Circular Air-Bold.svg#2983522fa941f456a748c9df597f77cf') format('svg');

  font-style:   normal;
  font-weight:  700;
}

@font-face {
  font-family: 'Circular-Air-Book';
  src: url('../fonts/Circular/Circular Air-Book.eot');
  src: url('../fonts/Circular/Circular Air-Book.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Circular/Circular Air-Book.woff') format('woff'),
    url('../fonts/Circular/Circular Air-Book.ttf')  format('truetype'),
    url('../fonts/Circular/Circular Air-Book.svg#4c0e205f0e077410e8206c0cea0eb832') format('svg');

  font-style:   normal;
  font-weight:  400;
}

@font-face {
  font-family: 'Circular-Air-Light';
  src: url('../fonts/Circular/Circular Air-Light.eot');
  src: url('../fonts/Circular/Circular Air-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Circular/Circular Air-Light.woff') format('woff'),
    url('../fonts/Circular/Circular Air-Light.ttf')  format('truetype'),
    url('../fonts/Circular/Circular Air-Light.svg#4c0e205f0e077410e8206c0cea0eb832') format('svg');

  font-style:   normal;
  font-weight:  300;
}
