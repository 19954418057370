footer {
  background: #f5f5f5;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  padding: 30px 0;

  @include media(tablet) {
    font-size: 16px;
    line-height: 20px;
    padding: 40px 0;
  }

  @include media(desktop) {
    padding: 50px 0 60px;
    font-size: 18px;
    line-height: 24px;
    text-align: left;
  }

  .footer-inner {
    max-width: 1280px;
    padding: 0 20px 0 40px;
    margin: 0 auto;
  }


  .info {

    @include media(desktop) {
      //@include span-columns(6);
    }

  }

  .license {
    margin-bottom: 16px;

    @include media(tablet) {
      margin-bottom: 16px;
    }

    @include media(desktop) {
      margin-bottom: 24px;
    }

  }

  .badges {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    display: flex;
    justify-content: center;
    margin-top: 30px;

    @include media(desktop) {
      //@include span-columns(6);
      //@include omega;
      float: right;
      display: flex;
      justify-content: flex-end;
      margin-top: 0;
    }

    li {

      a {
        display: block;
        width: 40px;
        height: 40px;
        font-size: 0;
        margin: 0 28px;
        background-position: center center;
        background-size: contain;

        &.houzz1 {
          background-image: url('../images/badge-houzz-featured.png');
        }

        &.houzz2 {
          background-image: url('../images/badge-houzz-bestof.png');
        }

        &.yelp {
          width: 57px;
          height: 40px;
          background-image: url('../images/badge-yelp-5star.png');
        }
      }

      img {
        width: 50%;
        display: block;
      }

    }
  }

}
