/*
  1. Use a more-intuitive box-sizing model.
*/
*, *::before, *::after {
  box-sizing: border-box;
}
/*
  2. Remove default margin
*/
* {
  margin: 0;
}
/*
  3. Allow percentage-based heights in the application
*/
html, body {
  height: 100%;
}
/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
/*
  6. Improve media defaults
*/
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}
/*
  7. Remove built-in form typography styles
*/
input, button, textarea, select {
  font: inherit;
}
/*
  8. Avoid text overflows
*/
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
/*
  9. Create a root stacking context
*/
#root, #__next {
  isolation: isolate;
}



article {

  .main-image {
    margin-bottom: 18px;
    position: relative;

    .swiper-slide {
      position: relative;
      background-position: center center;
      background-size: cover;
      max-height: 680px;

        &::before {
          display: block;
          padding-bottom: 62.2568093385%;
          content: '';
        }
    }

    .tout {
      position: relative;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 1;
      background-color: rgba(0, 0, 0, .5);
      color: $white;
      text-align: center;
      padding: 20px;

      @include media(tablet) {
        padding: 30px;
      }

      @include media(desktop) {
        position: absolute;
        top: 70px;
        bottom: auto;
        left: 0;
        width: 42%;
        padding: 40px;
      }

      p {
        margin-bottom: 0;
        font-size: 14px;
        line-height: 20px;

        @include media(tablet) {
          font-size: 16px;
          line-height: 22px;
        }

        @include media(desktop) {
          font-size: 18px;
          line-height: 26px;
        }

        span {
          @include nowrap;
        }

      }

    }

  }

  .company-image {
    position: relative;
    background-position: center center;
    background-size: cover;
    max-height: 680px;
    overflow: hidden;
  }

  .main-text {
    padding-top: 10px;
    padding-bottom: 10px;

    @include media(tablet) {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    @include media(desktop) {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    p {
      text-align: center;
      font-size: 14px;
      line-height: 18px;

      @include media(tablet) {
        font-size: 18px;
        line-height: 24px;
        padding: 0 10%;
      }

      @include media(desktop) {
        font-size: 28px;
        line-height: 40px;
      }

    }



    a {
      font-family: Circular-Air-Bold;
      font-size: 12px;

      @include media(tablet) {
        font-size: 14px;
        line-height: 24px;
      }

      @include media(desktop) {
        font-size: 22px;
        line-height: 40px;
      }

    }

  }


}
