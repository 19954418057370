.projects {
  position: relative;
  overflow: hidden;
  margin-bottom: 50px;

  li {
    list-style: none;

    &:nth-child(odd) {
      background-color: rgba(187, 187, 187, .21);
    }

    .property-container {
      display: grid;

      @include media(desktop) {
        display: grid;
        grid-template-columns: 2fr 1fr;
        padding-bottom: 0;
        align-items: center;
      }
    }

    .property {
      max-width: 100%;
      overflow: hidden;
    }

    .swiper-container {
      position: relative;

      img {
        display: block;
        margin: 0;
        padding: 0;
      }

    }

    .swiper-slide {
      position: relative;
    }

    --swiper-theme-color: #FFF;
    --swiper-pagination-bullet-width: 12px;
    --swiper-pagination-bullet-height: 12px;
    --swiper-pagination-bullet-horizontal-gap: 10px;
    --swiper-pagination-bullet-inactive-color: #FFF;

    .swiper-pagination {
      position: absolute;
      right: 0;
      bottom: 10px;
      left: 0;

      @include media(tablet) {
        bottom: 15px;
      }

    }

    .swiper-container-horizontal {

      .swiper-pagination-bullets {
        -webkit-tap-highlight-color: transparent;

        .swiper-pagination-bullet {
          transition: opacity .15s;
          opacity: .5;

          &.swiper-pagination-bullet-active {
            opacity: 1;
          }
        }
      }

    }

    .text-container {
      padding: 20px 40px 20px;

      h2 {
        margin-bottom: 12px;
        margin-top: 0;
        font-size: 24px;
        line-height: 1.25;
        font-weight: normal;
        letter-spacing: -1px;

        @include media(tablet) {
          font-size: 30px;
          margin-bottom: 16px;
        }

        @include media(desktop) {
          font-size: 28px;
        }

      }

      p {
        font-size: 13px;
        line-height: 1.25;

        @include media(tablet) {
          font-size: 15px;
          line-height: 1.3;
        }

      }

    }

  }

}
