body {
  font-family: 'Circular-Air-Book', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  background: #fff;
  color: #3b3b3b;
  font-feature-settings: 'kern', 'liga', 'pnum';
  font-size: $base-font-size;
  line-height: $base-line-height;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;

}

p {
  margin: 0 0 $small-spacing;
}

a {
  outline: none;
  text-decoration: none;
  color: #767676;
  transition: color $base-duration $base-timing;

  &:hover {
    text-decoration: underline;
  }

}

strong {
  font-weight: bold;
}

img,
picture {
  margin: 0;
  width: 100%;
  border:none;
  display:block;
}

main h1 {
  //@include position(absolute, 10px -3px 0 0);
  // pointer-events: none;
  // background: #ddd;
  // z-index: 2;
  // color: #fff;
  // font-size: 24px;
  // font-size: 7vw;
  // line-height: 50px;
  // line-height: 12vw;
  // letter-spacing: -1px;
  // text-align: center;
  // margin: 0;
  // text-shadow: 1px 0 1px rgba(0,0,0,.05);

    //.screen-reader-only
    border: 0;
    clip: rect(0, 0, 0, 0);
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    margin: -1px;
    padding: 0;

}

article {
  font-size: 16px;
  line-height: 20px;

  p {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 20px;
    line-height: 24px;
    font-family: $sanchez;
    margin-bottom: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #757575;

    @include media(tablet) {
      font-size: 22px;
      line-height: 26px;
    }

  }

  h3 {
    font-size: 16px;
    line-height: 20px;
    font-family: $circularAirBold;
    margin-bottom: 0;
  }

}

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.screen-reader-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  margin: -1px;
  padding: 0;
}
