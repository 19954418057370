.company,
.contact {

  article {

    .main-image {
      margin-bottom: 0;

      @include media(desktop) {
        margin: 30px 0 0;
      }

    }

    .main-text {
      padding: 0;
      text-align: left;
      margin-top: 20px;
      margin-bottom: 30px;
      padding: 0 20px;

      @include media(desktop) {
        margin-top: 26px;
      }

      p,
      a {
        text-align: left;
        font-size: 14px;
        line-height: 1.4;

        @include media(tablet) {
          font-size: 16px;
        }

        @include media(desktop) {
          font-size: 18px;
        }

      }

      a {
        font-weight: normal;
      }

      address {
        font-style: normal;
      }

      h3 {
        text-transform: uppercase;
        color: #000;
        font-size: 14px;

        @include media(tablet) {
          padding: 0 10%;
        }

      }

    }

  }

}

.company article {
  @include media(desktop) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.contact {

  article {

    .main-text {
      padding: 0;
      text-align: center;
      margin-top: 40px;
      margin-bottom: 40px;

      @include media(desktop) {
        max-width: 100%;
        margin-top: 80px;
        margin-bottom: 100px;
      }


      p,
      a {
        text-align: center;
        font-size: 14px;
        line-height: 22px;

        @include media(tablet) {
          font-size: 16px;
          line-height: 24px;
        }

        @include media(desktop) {
          font-size: 20px;
          line-height: 30px;
          margin-bottom: 30px;
        }

      }

      a {
        font-weight: normal;
      }

      address {
        font-style: normal;
      }

      h3 {
        text-transform: uppercase;
        color: #000;
        font-size: 14px;

        @include media(tablet) {
          padding: 0 10%;
        }

      }

    }

  }

}



