.main-nav {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity $base-duration $base-timing;
  background-color: rgba(255, 255, 255, .92);
  text-align: center;
  z-index: 100;
  padding-top: 80px;
  visibility: hidden;
  opacity: 0;

  @include media(desktop) {
    display: block;
    position: relative;
    background-color: transparent;
    justify-self: flex-end;
    text-align: right;
    padding-top: 0;
    visibility: visible;
    opacity: 1;
  }

  &.active {
    visibility: visible;
    opacity: 1;
  }

  a {
    display: block;
    font-size: 30px;
    text-transform: uppercase;
    margin-bottom: 20px;
    text-decoration: none;

    @include media(desktop) {
      display: inline-block;
      margin-left: 50px;
      margin-bottom: 0;
      font-size: 20px;
    }

    &:hover {
      text-decoration: none;
      color: #3b3b3b;
    }

    &.active {

      @include media(desktop) {
        text-decoration: underline;
        color: #3b3b3b;
      }

    }

  }

}

.hamburger-icon {
  position: absolute;
  right: 10px;
  z-index: 1000;
  justify-self: flex-end;
  width: 44px;
  height: 44px;
  display: block;
  cursor: pointer;
  text-indent: -9999em;
  -webkit-tap-highlight-color: transparent;
  padding-top: 33px;

  @include media(desktop) {
    display: none;
  }

  a,
  a::before,
  a::after {
    transition: all 150ms ease-in-out;
    width: 30px;
    height: 2px;
    cursor: pointer;
    background: $dim-gray;
    position: absolute;
    display: block;
    content: '';
  }

  a:before {
    top: -9px;

  }

  a:after {
    bottom: -9px;

  }

  &.active {

    a {
      background-color: transparent;

      &:before {
        transform: rotate(45deg);
        top: 0;
        width: 30px;
      }

      &:after {
        transform: rotate(-45deg);
        top: 0;
        width: 30px;
      }

    }

  }

}
