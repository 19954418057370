header {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr;
  margin: 0 auto;
  max-width: 1280px;
  padding: 24px 0;

  @include media(desktop) {
    padding: 40px 0;
  }

  .logo-container {
    padding: 0 25px;
    margin: 0 0 0 -2px;

    a {
      width: 220px;
      height: 21px;
      font-size: 0;
      display: block;
      background: url('../images/logo.svg') no-repeat center center;
      background-size: 220px auto;

      @include media(desktop) {
        width: 310px;
        height: 32px;
        background-size: 310px auto;
      }

    }

  }//.logo-container

}// header
